import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-location-card',
	templateUrl: './location-card.component.html',
	styleUrls: ['./location-card.component.css'],
})
export class LocationCardComponent implements OnInit {
	@Input() flight: any = null;

	mapOptions: google.maps.MapOptions = {
		mapId: 'LOC_MAP',
		center: { lat: 51, lng: 5 },
		zoom: 14,
		mapTypeId: 'hybrid',
		// remove the default UI
		disableDefaultUI: true,
	};

	constructor() {}

	ngOnInit(): void {
		if (this.flight?.location) {
			this.mapOptions.center = {
				lat: this.flight.location.latitude,
				lng: this.flight.location.longitude,
			};
		}
	}

	opengm(meetingPointSameAsTakeOff: boolean) {
		let url = '';
		if (meetingPointSameAsTakeOff) {
			url = `https://maps.google.com/?q=${this.flight?.location?.latitude},${this.flight?.location?.longitude}`;
		} else {
			url = this.flight?.meetingPointURL;
		}
		if (url !== '') window.open(url, '_blank');
	}
}
