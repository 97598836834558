<mat-card appearance="outlined" class="mat-card-container">
	<div style="display: flex; justify-content: space-between; align-items: center; gap: 5px; margin: 20px">
		<div style="display: flex; align-items: center; gap: 5px">
			<mat-icon>event_available</mat-icon>
			{{ getDay() }} - {{ getHour() }}
		</div>
		<div style="display: flex; align-items: center; gap: 5px">
			<mat-icon>location_on</mat-icon>
			{{ booking.flight.location.name }}
		</div>
	</div>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div *ngIf="booking.status == 'ADDE'">
			<div style="display: flex; justify-content: center; align-items: center">
				<p style="font-size: xx-large">{{ 'FLIGHTCARD.request_submitted' | translate }}</p>
			</div>
			<div style="display: flex; align-items: center">
				<p>{{ 'FLIGHTCARD.added_explanation' | translate }}</p>
			</div>
		</div>
		<div *ngIf="booking.status == 'CONF'">
			<div style="display: flex; justify-content: center; align-items: center">
				<!-- <p style="font-size: xx-large">{{ 'FLIGHTCARD.request_confirmed' | translate }}</p> -->
				<p style="font-size: xx-large">{{ getDay() | capitalize }} - {{ getHour() }}</p>
			</div>
			<div style="display: flex; align-items: center">
				<p>{{ ('FLIGHTCARD.confirmed_explanation' | translate).replace('{0}', getDay()).replace('{1}', getHour()) }}</p>
			</div>
			<div style="display: flex; justify-content: center; align-items: center">
				<button mat-raised-button class="btn" (click)="downloadCalendar()" class="btn">
					{{ 'FLIGHTCARD.add_to_my_calendar' | translate }}
				</button>
			</div>
		</div>
		<div *ngIf="booking.status == 'INVI'">
			<div style="display: flex; justify-content: center; align-items: center">
				<p style="font-size: xx-large">{{ 'FLIGHTCARD.request_invited' | translate }}</p>
			</div>
			<div style="display: flex; align-items: center">
				<p>{{ ('FLIGHTCARD.invited_explanation' | translate).replace('{0}', getDay()).replace('{1}', getHour()) }}</p>
			</div>
			<div style="display: flex; justify-content: center; align-items: center; gap: 10px">
				<button mat-raised-button class="btn" (click)="openConfirmationPopup($event)" class="btn">
					{{ 'FLIGHTCARD.decline' | translate }}
				</button>
				<button mat-raised-button class="btn" (click)="acceptFlight()" class="btn">
					{{ 'FLIGHTCARD.accept' | translate }}
				</button>
			</div>
		</div>
	</mat-card-content>
</mat-card>
