<mat-card class="mat-card-container">
	<mat-card-title class="mat-title">
		<span class="mat-title-span">{{ 'FLIGHTPICKERCARD.Choose_a_flight' | translate }}</span>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div
			style="
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				margin-top: 20px;
			"
		>
			<div style="display: flex; justify-content: center; align-items: center; gap: 5px">
				<mat-icon>flight_takeoff</mat-icon>
				<span>{{ 'FLIGHTPICKERCARD.upcoming_flights_in_your_area' | translate | capitalize }}</span>
			</div>
			<div style="display: flex; justify-content: center; align-items: center">
				<button [routerLink]="['/flights']" mat-raised-button class="btn">
					{{ 'FLIGHTPICKERCARD.Choose_a_flight' | translate }}
				</button>
			</div>
		</div>
	</mat-card-content>
</mat-card>
