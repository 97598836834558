import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Booking, FlightStatus, MyflightFlights } from 'src/gen-types';

@Component({
	selector: 'app-booking-card',
	templateUrl: './booking-card.component.html',
	styleUrls: ['./booking-card.component.css'],
})
export class BookingCardComponent {
	@Input() booking: any = null;
	@Input() flight: any;
	@Input() operatorSettings: any = null;

	constructor(private router: Router) {}

	navigateToBooking() {
		if (!this.booking.flight || this.booking?.flight?.status === FlightStatus.Sch) {
			this.router.navigate(['/booking']);
		}
	}
}
