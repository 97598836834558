<mat-card class="mat-card-container">
	<mat-card-title class="mat-title">
		<span class="mat-title-span">{{ 'LOCATIONCARD.flightlocation' | translate }}</span>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div *ngIf="flight && flight?.status !== 'SCH'" style="margin-top: 5px">
			<div *ngIf="flight?.meetingPointSameAsTakeOff && flight?.location?.latitude && flight?.location?.latitude !== 0">
				<google-map width="100%" [options]="mapOptions">
					<map-advanced-marker
						[position]="{ lat: flight?.location?.latitude, lng: flight?.location?.longitude }"
						[title]="flight?.location?.name"
					>
						<!-- <agm-info-window>{{ flight?.location?.name }}</agm-info-window> -->
					</map-advanced-marker>
				</google-map>
			</div>
			<div *ngIf="!flight?.meetingPointSameAsTakeOff && flight?.meetingPointLatitude && flight?.meetingPointLongitude">
				<google-map width="100%" [options]="mapOptions">
					<map-advanced-marker
						[position]="{ lat: flight?.meetingPointLatitude, lng: flight?.meetingPointLongitude }"
						[title]="'LOCATIONCARD.meeting_point' | translate"
					>
						<!-- <agm-info-window>{{ 'LOCATIONCARD.meeting_point' | translate }}</agm-info-window> -->
					</map-advanced-marker>
				</google-map>
			</div>
			<div>
				@if (flight?.meetingPointSameAsTakeOff) {
				<p style="font-size: x-large">{{ 'LOCATIONCARD.meeting_point' | translate | capitalize }}</p>
				<p>{{ flight?.location?.address }}, {{ flight?.location?.city }}</p>
				@if (flight?.location?.description) {
				<p>{{ flight?.location?.description }}</p>
				} } @else {
				<p style="font-size: x-large">{{ 'LOCATIONCARD.meeting_point' | translate | capitalize }}</p>
				<p>{{ flight?.meetingPointAddress }}</p>
				}

				<div style="display: flex; justify-content: center; align-items: center">
					<button mat-raised-button class="btn" (click)="opengm(flight?.meetingPointSameAsTakeOff)">
						{{ 'LOCATIONCARD.open_in_maps' | translate }}
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="!flight || flight?.status === 'SCH'">
			<p>{{ 'LOCATIONCARD.scheduled_flight_message' | translate }}</p>
		</div>
	</mat-card-content>
</mat-card>
