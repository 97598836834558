import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { UrlService } from './_services/url.service';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import English locale
import 'dayjs/locale/fr'; // Import French locale
import 'dayjs/locale/de'; // Import German locale
import 'dayjs/locale/nl'; // Import Dutch locale
import tinycolor from 'tinycolor2';

interface Color {
	name: string;
	hex: string;
	darkContrast: boolean;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	defaultPrimaryColor = '#ab00dd';
	defaultAccentColor = '#faa7f7';

	language = 'nl';
	dateLocale = 'nl-BE';
	bookingInfo: { namespace: string; bookingId: string; legacy: boolean } = {
		namespace: '',
		bookingId: '',
		legacy: false,
	};
	defaultColors = ['#5ca4ff', '#2a2e40', '#ffffff', '#2a2e40'];
	defaultLanguage = 'nl';
	installPromptEvent: any;

	constructor(
		private translateService: TranslateService,
		private dateAdapter: DateAdapter<Date>,
		private urlService: UrlService
	) {}

	ngOnInit() {
		// this.setColorScheme(this.defaultColors);
		this.setLanguage(this.defaultLanguage);
		this.translateService.onLangChange.subscribe((event) => {
			dayjs.locale(event.lang);
			this.dateAdapter.setLocale(event.lang);
		});
		this.urlService.getMyFlight();
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				if (myflight.booking) this.setLanguage(myflight.booking.contactLanguage);
				if (myflight.tenantSetting?.operatorSettings?.myflight?.colors) {
					this.setColorScheme(myflight.tenantSetting.operatorSettings.myflight.colors);
				} else {
					this.setColorScheme(this.defaultColors);
				}
			} else {
				this.setColorScheme(this.defaultColors);
			}
		});
	}

	setColorScheme(colors: any) {
		document.documentElement.style.setProperty('--primary-background-color', colors[0]);
		document.documentElement.style.setProperty('--secondary-background-color', colors[1]);
		document.documentElement.style.setProperty('--primary-foreground-color', colors[2]);
		document.documentElement.style.setProperty('--secondary-foreground-color', colors[3]);

		const dynamicColorPalette = this.computeThemePalette(
			colors[1] || this.defaultPrimaryColor,
			colors[2] || this.defaultAccentColor
		);
		this.updateThemePalette(dynamicColorPalette);
	}

	private updateThemePalette(colors: Color[]) {
		// Set the values of the CSS variables for each shade of the primary
		// and accent theme colors, so that they can be used in theme.scss
		// to set the Angular Material theme
		colors.forEach((color) => {
			document.documentElement.style.setProperty(`--theme-dynamic-palette-${color.name}`, color.hex);
			document.documentElement.style.setProperty(
				`--theme-dynamic-palette-contrast-${color.name}`,
				color.darkContrast ? 'rgba(black, 0.87)' : 'white'
			);
		});
	}

	setLanguage(language: any) {
		switch (language) {
			case 'nl': {
				this.language = 'nl';
				this.dateLocale = 'nl-BE';
				break;
			}
			case 'fr': {
				this.language = 'fr';
				this.dateLocale = 'fr-BE';
				break;
			}
			case 'en': {
				this.language = 'en';
				this.dateLocale = 'en-US';
				break;
			}
			case 'de': {
				this.language = 'de';
				this.dateLocale = 'de-DE';
				break;
			}
			default:
				this.language = 'nl';
				this.dateLocale = 'nl-BE';
				break;
		}
		this.translateService.setDefaultLang(this.language);
		this.translateService.use(this.language);
	}

	// Listen for the beforeinstallprompt event
	@HostListener('window:beforeinstallprompt', ['$event'])
	onBeforeInstallPrompt(event: Event) {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		event.preventDefault();
		// Store the event for later use.
		this.installPromptEvent = event;
	}

	// Call this function when the user clicks a button to install the app.
	promptForInstallation() {
		if (this.installPromptEvent) {
			this.installPromptEvent.prompt(); // show the install app prompt
			this.installPromptEvent.userChoice.then((choiceResult: any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the install prompt');
				} else {
					console.log('User dismissed the install prompt');
				}
				this.installPromptEvent = null; // clear the saved prompt event
			});
		}
	}

	private computeThemePalette(primaryHex: string, accentHex: string): Color[] {
		// Uses tinycolor library to get lighter and darker versions of primary
		// and accent colors, so that we can make a full theme palette from them

		return [
			this.getColorObject(tinycolor(primaryHex).lighten(52), '50'),
			this.getColorObject(tinycolor(primaryHex).lighten(37), '100'),
			this.getColorObject(tinycolor(primaryHex).lighten(26), '200'),
			this.getColorObject(tinycolor(primaryHex).lighten(12), '300'),
			this.getColorObject(tinycolor(primaryHex).lighten(6), '400'),
			this.getColorObject(tinycolor(primaryHex), '500'),
			this.getColorObject(tinycolor(primaryHex).darken(6), '600'),
			this.getColorObject(tinycolor(primaryHex).darken(12), '700'),
			this.getColorObject(tinycolor(primaryHex).darken(18), '800'),
			this.getColorObject(tinycolor(primaryHex).darken(24), '900'),
			this.getColorObject(tinycolor(accentHex).lighten(50), 'A100'),
			this.getColorObject(tinycolor(accentHex), 'A200'),
			this.getColorObject(tinycolor(accentHex).darken(20), 'A400'),
			this.getColorObject(tinycolor(accentHex).darken(50), 'A700'),
		];
	}

	private getColorObject(value: tinycolor.Instance, name: string): Color {
		const c = tinycolor(value);
		return {
			name: name,
			hex: c.toHexString(),
			darkContrast: c.isLight(), // determine if the contrast for this color should be black or white
		};
	}
}
