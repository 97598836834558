import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { collection, Firestore, onSnapshot, orderBy, query } from '@angular/fire/firestore';
import dayjs from 'dayjs';
import { Observable, from } from 'rxjs';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { UrlService } from 'src/app/_services/url.service';

@Component({
	selector: 'app-chat-card',
	templateUrl: './chat-card.component.html',
	styleUrls: ['./chat-card.component.css'],
})
export class ChatCardComponent implements OnInit {
	chats: any[] = [];
	messagesCollection: string;

	constructor(private firestore: Firestore, private urlService: UrlService) {
		this.urlService.myflight.subscribe((myflight) => {
			this.messagesCollection = myflight.messagesCollection;
		});
	}

	ngOnInit(): void {
		this.subscribeToMessages(this.messagesCollection);
	}

	subscribeToMessages(messagesCollection): void {
		if (messagesCollection) {
			const collection_ = collection(this.firestore, messagesCollection);
			const query_ = query(collection_, orderBy('date.sms'));

			onSnapshot(query_, (snapshot) => {
				this.chats = snapshot.docs.map((doc) => doc.data());
			});
		}
	}

	isDifferentDay(date1: Date, date2: Date): boolean {
		return (
			date1.getDate() !== date2.getDate() ||
			date1.getMonth() !== date2.getMonth() ||
			date1.getFullYear() !== date2.getFullYear()
		);
	}

	formatTime(date: Date): string {
		return dayjs(date).format('HH:mm');
	}
	formatDate(date: Date): string {
		const _date = dayjs(date);
		return _date.isSame(dayjs(), 'day') ? 'today' : _date.format('dd D/MM/YY');
	}
}
