import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FlightStatus } from 'src/gen-types';
import { UrlService } from '../../_services/url.service';

@Component({
	selector: 'app-booking-page',
	templateUrl: './booking-page.component.html',
	styleUrls: ['./booking-page.component.css'],
})
export class BookingPageComponent implements OnInit {
	booking: any;
	regions: any;
	isReadOnly = true;
	myTenantId: string = '';
	myBookingId: string = '';

	reservationForm = new UntypedFormGroup({
		contactName: new UntypedFormControl(''),
		contactPhone: new UntypedFormControl('+32'),
		contactEmail: new UntypedFormControl(''),
		contactLanguage: new UntypedFormControl(''),
	});

	constructor(private urlService: UrlService, private translate: TranslateService, private router: Router) {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				this.booking = myflight.booking;
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = this.booking.id;
				if (this.booking) this.fillInForm();
				if (!this.booking?.flight || this.booking?.flight?.status === FlightStatus.Sch) {
					this.reservationForm.get('language')?.enable();
					this.isReadOnly = false;
				}
				this.regions = myflight?.regions;
			}
			this.booking = myflight?.booking;
		});
	}

	// When this page is opened, the service is queried to retrieve the known booking data so we can fill in the fields
	ngOnInit(): void {
		this.reservationForm.get('language')?.disable();
	}

	// Using the currently saved booking, fills in all fields with known data
	fillInForm(): void {
		this.reservationForm.patchValue(this.booking);
	}

	// gets called any time the user exits focus out of the form.
	// If the user made any changes, we send the current values to the server to update the current booking information.
	saveForm(quit: boolean) {
		if (!this.reservationForm.pristine) {
			this.urlService.updateMyBooking(this.reservationForm.value, this.myBookingId, this.myTenantId).subscribe(
				() => {
					this.reservationForm.markAsPristine();
					this.translate.use(this.reservationForm.controls['contactLanguage'].value);
					if (quit) {
						this.navigateBack();
					}
				},
				(error) => {
					console.log('Error occured while updating booking!: ', error);
				}
			);
		}
		if (this.reservationForm.pristine && quit) {
			this.navigateBack();
		}
	}

	navigateBack() {
		this.router.navigate(['']);
	}
}
