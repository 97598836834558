import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	AddBookingToMyFlightDocument,
	BookingStatus,
	ConfirmBookingToMyFlightDocument,
	MyBookingInput,
	MyflightBookingDocument,
	MyflightBookingGQL,
	MyflightDocument,
	MyflightFlightsGQL,
	MyflightGQL,
	MyflightLegacyGQL,
	MyPassengerInput,
	RejectBookingToMyFlightDocument,
	UpdateMyBookingDocument,
	UpdateMyPassengerDocument,
} from 'src/gen-types';
export type BookingInfo = {
	bookingReference: string | null;
	bookingEmail: string | null;
	bookingLegacy: string | null;
};

@Injectable({
	providedIn: 'root',
})
export class UrlService {
	private myflightSubject = new BehaviorSubject<any>(null);
	myflight = this.myflightSubject.asObservable();

	constructor(
		private apollo: Apollo,
		private myflightBookingGQL: MyflightBookingGQL,
		private myflightFlightsGQL: MyflightFlightsGQL,
		private myflightLegacyGQL: MyflightLegacyGQL,
		private router: Router
	) {}

	// Gets info from current window
	getInfo(): BookingInfo {
		const r = new URLSearchParams(window.location.search).get('r');
		const e = new URLSearchParams(window.location.search).get('e');
		const l = new URLSearchParams(window.location.search).get('l');
		const rLocal = localStorage.getItem('_UP_BOOKING_REFERENCE');
		const eLocal = localStorage.getItem('_UP_BOOKING_EMAIL');
		const lLocal = localStorage.getItem('_UP_LEGACY_BOOKING');

		let bookingReference = null;
		let bookingEmail = null;
		let bookingLegacy = null;

		if (l || lLocal) {
			if (l) {
				bookingLegacy = l;
				localStorage.setItem('_UP_LEGACY_BOOKING', bookingLegacy);
			} else if (lLocal) {
				bookingLegacy = lLocal;
			}
		}

		if (r || rLocal) {
			if (r) {
				bookingReference = r;
				localStorage.setItem('_UP_BOOKING_REFERENCE', bookingReference);
			} else if (rLocal) {
				bookingReference = rLocal;
			}
		}
		if (e || eLocal) {
			if (e) {
				bookingEmail = e;
				localStorage.setItem('_UP_BOOKING_EMAIL', bookingEmail);
			} else if (eLocal) {
				bookingEmail = eLocal;
			}
		}

		if (bookingReference && bookingEmail) bookingLegacy = null;

		return { bookingReference, bookingEmail, bookingLegacy };
	}

	getMyFlight() {
		const bookingInfo = this.getInfo();
		if (bookingInfo.bookingLegacy) {
			this.myflightLegacyGQL
				.watch({
					legacyBooking: bookingInfo?.bookingLegacy,
				})
				.valueChanges.subscribe((result) => {
					if (result.data.myflightLegacy) this.myflightSubject.next(result.data.myflightLegacy);
				});
		} else {
			this.myflightBookingGQL
				.watch(
					{
						bookingReference: bookingInfo?.bookingReference,
						bookingEmail: bookingInfo?.bookingEmail,
					},
					{ fetchPolicy: 'cache-and-network' }
				)
				.valueChanges.subscribe((result) => {
					if (result.data.myflightBooking) {
						// if end of season is set, route to end of season page
						if (result.data.myflightBooking.tenantSetting?.operatorSettings?.myflight?.endOfSeason) {
							this.router.navigate(['/endofseason']);
						}
						// if booking beyond end date, route to end of season page
						const date = new Date();
						const endDate = new Date(result.data.myflightBooking.booking?.endDate);
						if (result.data.myflightBooking.booking?.endDate && date > endDate) {
							this.router.navigate(['/endofbooking']);
						}
						this.myflightSubject.next(result.data.myflightBooking);
					} else {
						this.router.navigate(['/login']);
					}
				});
		}
	}

	getFlights() {
		return this.myflightFlightsGQL
			.watch({
				bookingReference: this.getInfo().bookingReference,
				bookingEmail: this.getInfo().bookingEmail,
			})
			.valueChanges.pipe(map((result) => result.data));
	}

	updateMyBooking(myBookingInput: MyBookingInput, myBookingId: string, myTenantId: string) {
		return this.apollo
			.mutate<any>({
				mutation: UpdateMyBookingDocument,
				variables: {
					myBookingInput,
					myBookingId,
					myTenantId,
				},
				refetchQueries: [
					{
						query: MyflightBookingDocument,
						variables: this.getInfo(),
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	updateMyPassenger(
		myPassengerInput: MyPassengerInput,
		myPassengerId: string,
		myBookingId: string,
		myTenantId: string
	) {
		return this.apollo
			.mutate<any>({
				mutation: UpdateMyPassengerDocument,
				variables: {
					myPassengerInput,
					myPassengerId,
					myBookingId,
					myTenantId,
				},
				refetchQueries: [
					{
						query: MyflightBookingDocument,
						variables: this.getInfo(),
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}

	addBookingToMyFlight(myFlightId: string, myBookingId: string, myTenantId: string) {
		return this.apollo
			.mutate<any>({
				mutation: AddBookingToMyFlightDocument,
				variables: {
					myFlightId,
					myBookingId,
					myTenantId,
				},
				update: (cache, { data }) => {
					// Define the fragment
					const flightFragment = gql`
						fragment FlightFragment on Flight {
							date
							hour
							status
						}
					`;

					// Read the fragment from the cache using the cache ID
					const flight: any = cache.readFragment({
						id: `Flight:${myFlightId}`, // Cache ID
						fragment: flightFragment,
					});

					// Define the fragment
					const bookingFragment = gql`
						fragment BookingFragment on Booking {
							id
							status
							flight
						}
					`;

					// Read the fragment from the cache using the cache ID
					const booking: any = cache.readFragment({
						id: `Booking:${myBookingId}`, // Cache ID
						fragment: bookingFragment,
					});

					// update the booking with the flightFragment
					cache.writeFragment({
						id: `Booking:${myBookingId}`,
						fragment: bookingFragment,
						data: {
							...booking,
							status: BookingStatus.Adde,
							flight: { ...flight },
						},
					});
				},
				refetchQueries: [
					{
						query: MyflightBookingDocument,
						variables: this.getInfo(),
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
	confirmBookingToMyFlight(myBookingId: string, myTenantId: string) {
		return this.apollo
			.mutate<any>({
				mutation: ConfirmBookingToMyFlightDocument,
				variables: {
					myBookingId,
					myTenantId,
				},

				update: (cache, { data }) => {
					// Define the fragment
					const bookingFragment = gql`
						fragment BookingFragment on Booking {
							id
							status
						}
					`;

					// Read the fragment from the cache using the cache ID
					const booking: any = cache.readFragment({
						id: `Booking:${myBookingId}`, // Cache ID
						fragment: bookingFragment,
					});

					// update the booking with the flightFragment
					cache.writeFragment({
						id: `Booking:${myBookingId}`,
						fragment: bookingFragment,
						data: {
							...booking,
							status: BookingStatus.Conf,
						},
					});
				},
				refetchQueries: [
					{
						query: MyflightBookingDocument,
						variables: this.getInfo(),
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
	rejectBookingToMyFlight(myBookingId: string, myTenantId: string) {
		return this.apollo
			.mutate<any>({
				mutation: RejectBookingToMyFlightDocument,
				variables: {
					myBookingId,
					myTenantId,
				},
				update: (cache, { data }) => {
					// Define the fragment
					const bookingFragment = gql`
						fragment BookingFragment on Booking {
							id
							status
						}
					`;

					// Read the fragment from the cache using the cache ID
					const booking: any = cache.readFragment({
						id: `Booking:${myBookingId}`, // Cache ID
						fragment: bookingFragment,
					});

					// update the booking with the flightFragment
					cache.writeFragment({
						id: `Booking:${myBookingId}`,
						fragment: bookingFragment,
						data: {
							...booking,
							status: BookingStatus.Open,
						},
					});
				},
				refetchQueries: [
					{
						query: MyflightBookingDocument,
						variables: this.getInfo(),
					},
				],
			})
			.pipe(map((result: any) => result.data));
	}
}
