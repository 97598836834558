import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { bookingCodeValidator } from 'src/app/utility/bookingCodeValidator';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	langSelected: string = 'en';
	dateLocale = 'nl-BE';

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private translate: TranslateService,
		private dateAdapter: DateAdapter<Date>
	) {
		this.loginForm = formBuilder.group({
			reference: ['', [Validators.required, bookingCodeValidator()]],
			email: ['', [Validators.required, Validators.email]],
		});
	}

	ngOnInit(): void {
		// delete the local storage keys _UP_BOOKING-REFERENCE and _UP_BOOKING-EMAIL
		localStorage.removeItem('_UP_BOOKING_REFERENCE');
		localStorage.removeItem('_UP_BOOKING_EMAIL');
		this.langSelected = this.translate.currentLang;
		this.updateLang(this.langSelected);
	}

	submitForm(): void {
		this.router
			.navigate(['/'], {
				queryParams: {
					r: this.loginForm.value['reference'].toUpperCase(),
					e: this.shortenEmail(this.loginForm.value['email']),
				},
			})
			.then(() => {
				window.location.reload();
			});
	}

	private shortenEmail(email: string) {
		let shortEmail: string | null = null;
		if (email && email != '') {
			shortEmail = '';
			email.split('@').forEach((part) => {
				part.split('.').forEach((word) => (shortEmail += word.charAt(0).toLowerCase()));
			});
		}
		return shortEmail;
	}

	updateLang(language: any) {
		switch (language) {
			case 'nl': {
				this.langSelected = 'nl';
				this.dateLocale = 'nl-BE';
				break;
			}
			case 'fr': {
				this.langSelected = 'fr';
				this.dateLocale = 'fr-BE';
				break;
			}
			case 'en': {
				this.langSelected = 'en';
				this.dateLocale = 'en-US';
				break;
			}
			case 'de': {
				this.langSelected = 'de';
				this.dateLocale = 'de-DE';
				break;
			}
			default:
				this.langSelected = 'nl';
				this.dateLocale = 'nl-BE';
				break;
		}
		this.translate.setDefaultLang(this.langSelected);
		this.translate.use(this.langSelected);
		this.dateAdapter.setLocale(this.dateLocale);
		dayjs.locale(this.langSelected);
	}
}
