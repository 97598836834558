import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/_services/url.service';
import { ICalendar, CalendarOptions } from 'datebook';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-flight-card',
	templateUrl: './flight-card.component.html',
	styleUrls: ['./flight-card.component.css'],
})
export class FlightCardComponent implements OnInit {
	@Input() booking: any = null;
	@Output() flightDecision = new EventEmitter<any>();

	myTenantId: string = '';
	myBookingId: string = '';
	myTenantName: string = '';

	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private urlService: UrlService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				this.myTenantName = myflight.tenantSetting.name;
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = myflight.booking.id;
			}
		});
	}

	ngOnChanges() {
		if (this.booking) dayjs.locale(this.booking.contact_language);
	}

	getDay() {
		if (this.booking.flight.date) {
			return dayjs(this.booking.flight.date).format('dddd D MMM YYYY');
		}
		return null;
	}

	getHour() {
		if (this.booking.flight.hour) {
			return dayjs(this.booking.flight.hour).format('HH:mm');
		}
		return null;
	}

	// For rejecting the flight
	openConfirmationPopup(e: any) {
		e.stopPropagation();
		this.translate.get(['FLIGHTCARD.confirmation_title', 'FLIGHTCARD.confirmation_explanation']).subscribe((trans) => {
			var confirmationData = {
				title: trans['FLIGHTCARD.confirmation_title'],
				body: trans['FLIGHTCARD.confirmation_explanation'],
			};
			var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
				data: confirmationData,
			});
			const sub = dialogRef.componentInstance.onYes.subscribe(() => this.rejectFlight(e));
			dialogRef.afterClosed().subscribe(() => {
				sub.unsubscribe();
			});
		});
	}

	acceptFlight() {
		this.urlService.confirmBookingToMyFlight(this.myBookingId, this.myTenantId).subscribe();
	}

	rejectFlight(e: any) {
		this.urlService.rejectBookingToMyFlight(this.myBookingId, this.myTenantId).subscribe();
	}

	downloadCalendar() {
		const options: CalendarOptions = {
			title: this.translate.instant('FLIGHTCARD.flight', { tenantName: this.myTenantName }),
			location: this.booking.flight.location.name,
			start: dayjs(this.booking.flight.hour).toDate(),
			end: dayjs(this.booking.flight.hour).add(3, 'hours').toDate(),
		};

		const icalender = new ICalendar(options);
		const ics = icalender.render();
		const blob = new Blob([ics], {
			type: 'text/calendar',
		});
		FileSaver.saveAs(blob, 'my-balloon-flight.ics');
	}
}
