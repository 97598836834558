<div *ngIf="!this.doneLoading" class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'PASSENGERPAGE.retrieving_booking' | translate }}
</div>
<div *ngIf="this.doneLoading">
	<mat-card class="mat-card-container" style="margin: 10px">
		<mat-card-title class="mat-title">
			<span class="mat-title-span">{{ 'PASSENGERPAGE.passengers' | translate }}</span>
			<button mat-icon-button (click)="saveForm(true)">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<div class="info-explanation">
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'PASSENGERPAGE.explanation' | translate : { childAge } }}</span>
			</div>
			<div style="display: flex; justify-content: space-evenly; align-items: center; margin-top: 20px">
				<div
					class="number-box"
					style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: center; align-items: center"
				>
					<span>{{ booking.passengers.length }}</span>
					<mat-icon>groups</mat-icon>
					<span style="font-size: xx-small">{{ 'BOOKINGCARD.persons_on_board' | translate | capitalize }}</span>
				</div>
				<div
					class="number-box"
					style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: center; align-items: center"
				>
					<span>{{ children(booking) }}</span>
					<mat-icon>escalator_warning</mat-icon>
					<span style="font-size: xx-small">{{ 'BOOKINGCARD.children' | translate | capitalize }}</span>
				</div>
				<div
					class="number-box"
					style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: center; align-items: center"
				>
					<span>{{ disability(booking) }}</span>
					<mat-icon>accessible</mat-icon>
					<span style="font-size: xx-small">{{ 'BOOKINGCARD.assistance_needed' | translate | capitalize }}</span>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-accordion>
		<mat-expansion-panel
			#panel
			*ngFor="let passenger of this.passengers; let i = index"
			(opened)="panelOpened(i)"
			(closed)="panelClosed(i)"
			[expanded]="expandedPanels[i]"
		>
			<mat-expansion-panel-header>
				<mat-panel-title>{{ i + 1 }}. {{ allForms[i].get('name')?.value }}</mat-panel-title>
				<mat-panel-description>
					@if (allForms[i].get('disability')?.value) {
					<mat-icon>accessible</mat-icon>
					}
				</mat-panel-description>
			</mat-expansion-panel-header>
			<form [formGroup]="allForms[i]" style="margin-top: 20px">
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.name' | translate }}</mat-label>
						<mat-icon matIconPrefix>account_box</mat-icon>
						<input matInput type="text" formControlName="name" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>

				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.age' | translate }}</mat-label>
						<mat-icon matIconPrefix>diversity_3</mat-icon>
						<input matInput type="number" formControlName="age" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.weight' | translate }}</mat-label>
						<mat-icon matIconPrefix>speed</mat-icon>
						<input matInput type="number" formControlName="weight" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
						<span matTextSuffix>Kg</span>
					</mat-form-field>
				</div>
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.comments' | translate }}</mat-label>
						@if (allForms[i].get('disability')?.value) {
						<mat-icon matIconPrefix>accessible</mat-icon>
						} @else {
						<mat-icon matIconPrefix>info</mat-icon>
						}
						<input matInput type="text" formControlName="comments" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<div style="display: flex; flex-wrap: wrap; align-items: center; gap: 5px">
					<mat-slide-toggle formControlName="disability" (change)="leftFocus(i)">
						{{ 'PASSENGERPAGE.help_needed' | translate }}</mat-slide-toggle
					>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
</div>
