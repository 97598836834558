<mat-card class="mat-card-container" *ngIf="booking">
	<mat-card-title class="mat-title mat-title-end">
		<span class="mat-title-span">{{ 'BOOKINGCARD.mybooking' | translate }}</span>
		<button mat-icon-button (click)="navigateToBooking()" *ngIf="!booking.flight || booking.flight?.status === 'SCH'">
			<mat-icon>edit</mat-icon>
		</button>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div class="passenger-row" (click)="navigateToBooking()">
			<mat-icon>qr_code_2</mat-icon>
			<span>{{ booking.bookingCode }}</span>
		</div>
		<div class="passenger-row" (click)="navigateToBooking()">
			<mat-icon>account_box</mat-icon>
			<span>{{ booking.contactName }}</span>
		</div>
		<div class="passenger-row" (click)="navigateToBooking()">
			<mat-icon>email</mat-icon>
			<span>{{ booking.contactEmail }}</span>
		</div>
		<div class="passenger-row" (click)="navigateToBooking()">
			<mat-icon>phone</mat-icon>
			<span>{{ booking.contactPhone }}</span>
		</div>
		<div class="passenger-row" (click)="navigateToBooking()">
			<mat-icon>bubble_chart</mat-icon>
			<span>{{ booking.region.name }}</span>
		</div>
	</mat-card-content>
</mat-card>
