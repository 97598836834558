<div *ngIf="!this.booking" class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'BOOKINGPAGE.retrieving_booking' | translate }}
</div>
<div *ngIf="this.booking" style="margin: 10px">
	<mat-card appearance="outlined" class="mat-card-container">
		<mat-card-title class="mat-title">
			<span class="mat-title-span">{{ 'BOOKINGPAGE.mybooking' | translate }}</span>
			<button mat-icon-button (click)="saveForm(true)">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<form [formGroup]="reservationForm" (focusout)="saveForm(false)" style="margin-top: 20px">
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'BOOKINGPAGE.name' | translate }}</mat-label>
						<mat-icon matPrefix>account_box</mat-icon>
						<input matInput type="text" formControlName="contactName" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'BOOKINGPAGE.email' | translate }}</mat-label>
						<mat-icon matPrefix>email</mat-icon>
						<input matInput type="text" formControlName="contactEmail" readonly />
					</mat-form-field>
				</div>
				<div style="display: flex; flex-wrap: wrap; align-items: center">
					<mat-form-field appearance="outline" class="full-width">
						<mat-label>{{ 'BOOKINGPAGE.telephone' | translate }}</mat-label>
						<mat-icon matPrefix>phone</mat-icon>
						<input matInput type="text" formControlName="contactPhone" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<mat-radio-group class="radio-group" formControlName="contactLanguage" (change)="saveForm(false)">
					<mat-radio-button value="nl">Nederlands</mat-radio-button><br />
					<mat-radio-button value="fr">Français</mat-radio-button><br />
					<mat-radio-button value="en">English</mat-radio-button><br />
					<mat-radio-button value="de">Deutsch</mat-radio-button>
				</mat-radio-group>
			</form>
		</mat-card-content>
	</mat-card>
</div>
