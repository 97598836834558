<mat-card class="mat-card-container">
	<mat-card-title class="mat-title mat-title-end dialog-mat-title">
		<span class="mat-title-span dialog-mat-title-span">{{ data.title }}</span>
	</mat-card-title>
	<mat-divider class="dialog-divider"></mat-divider>
	<mat-card-content class="mat-content-container">
		<div>
			<p>{{ data.body }}</p>
		</div>
	</mat-card-content>
	<mat-dialog-actions align="end">
		<button mat-raised-button (click)="sayNo()" class="btn">{{ 'CONFIRMATIONDIALOG.decline' | translate }}</button>
		<button mat-raised-button (click)="sayYes()" class="btn">{{ 'CONFIRMATIONDIALOG.accept' | translate }}</button>
	</mat-dialog-actions>
</mat-card>
