<div *ngIf="!this.doneLoading" class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'FLIGHTPICKERPAGE.retrieving_flights' | translate }}
</div>
<div *ngIf="this.doneLoading" style="max-width: 480px; margin: 10px auto">
	<mat-card class="mat-card-container">
		<mat-card-title class="mat-title">
			<span class="mat-title-span">{{ 'FLIGHTPICKERPAGE.choose_your_flight' | translate }}</span>
			<button mat-icon-button [routerLink]="['/']">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<div
				class="number-box"
				style="
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					width: 120px;
					margin: 0 auto;
					margin-top: 20px;
				"
			>
				<span>{{ this.upcomingFlights.length }}</span>
				<mat-icon>flight_takeoff</mat-icon>
				<span style="font-size: xx-small">{{ 'FLIGHTPICKERPAGE.flights_found' | translate | capitalize }}</span>
			</div>

			<div class="info-explanation">
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'FLIGHTPICKERPAGE.explanation' | translate : { childAge: '99' } }}</span>
			</div>
			<mat-list>
				@for( flightOption of upcomingFlights; track $index) {
				<mat-divider></mat-divider>
				<div style="display: flex; justify-content: space-between; align-items: flex-start">
					<mat-list-item lines="3">
						@if (flightOption.passengerCounters.freeSpots >= passengersCount && flightOption.status != 'REA') {
						<mat-icon matListItemIcon>event_available</mat-icon>
						} @else {
						<mat-icon matListItemIcon>event_busy</mat-icon>
						}
						<span matListItemTitle>{{ getFormattedDate(flightOption.date) }} - {{ getHour(flightOption.hour) }}</span>
						<span matListItemLine>{{ flightOption.location.name }}</span>
					</mat-list-item>
					@if (flightOption.passengerCounters.freeSpots >= passengersCount && flightOption.status != 'REA') {

					<button mat-raised-button class="btn button-list" (click)="openConfirmationPopup($event, flightOption)">
						{{ 'FLIGHTPICKERPAGE.request' | translate }}
					</button>
					} @else { @if (flightOption.passengerCounters.freeSpots === 0 || flightOption.status === 'REA') {
					<span class="button-list">{{ 'FLIGHTPICKERPAGE.flight_full' | translate }} </span>

					} @else {
					<span class="button-list"
						>{{
							('FLIGHTPICKERPAGE.flight_partially_full' | translate).replace(
								'{0}',
								flightOption.passengerCounters.freeSpots <= 0 ? 0 : flightOption.passengerCounters.freeSpots
							)
						}}
					</span>

					} }
				</div>
				<!-- @if (flightOption.passengerCounters.freeSpots > 0 && flightOption.status != 'REA') {} @else {
				<span style="display: flex; justify-content: center">
					{{ 'FLIGHTPICKERPAGE.unfortunately_this_flight_is_fully_booked' | translate }}
				</span>
				}  -->
				}
			</mat-list>
		</mat-card-content>
	</mat-card>
</div>
