import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-bottom-navbar',
	templateUrl: './bottom-navbar.component.html',
	styleUrls: ['./bottom-navbar.component.css'],
})
export class BottomNavbarComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
