import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
	onYes = new EventEmitter();
	onNo = new EventEmitter();

	ngOnInit(): void {}

	closeModal() {
		this.dialogRef.close();
	}

	sayYes() {
		this.onYes.emit();
		this.dialogRef.close();
	}

	sayNo() {
		this.onNo.emit();
		this.dialogRef.close();
	}
}
