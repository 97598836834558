<mat-card appearance="outlined" class="mat-card-container">
	<mat-card-header>
		<mat-card-title> {{ 'LOGINPAGE.title' | translate }} </mat-card-title>
	</mat-card-header>
	<mat-card-content class="mat-content-container">
		<form [formGroup]="loginForm" (submit)="submitForm()" style="display: flex; flex-direction: column; gap: 10px">
			<div>
				<mat-form-field appearance="outline" class="full-width">
					<mat-label>{{ 'LOGINPAGE.reference' | translate }}</mat-label>
					<mat-icon matPrefix>qr_code_2</mat-icon>
					<input matInput type="text" required formControlName="reference" />
					<mat-error *ngIf="loginForm.controls.reference.hasError('required')">{{
						'LOGINPAGE.required' | translate
					}}</mat-error>
					<mat-error
						*ngIf="
							!loginForm.controls.reference.hasError('required') &&
							loginForm.controls.reference.hasError('invalidBookingCode')
						"
						>{{ 'LOGINPAGE.invalidBookingCode' | translate }}</mat-error
					>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field appearance="outline" class="full-width">
					<mat-label>{{ 'LOGINPAGE.email' | translate }}</mat-label>
					<mat-icon matPrefix>email</mat-icon>
					<input matInput type="text" required formControlName="email" />
					<mat-error *ngIf="loginForm.controls.email.errors?.required">{{
						'LOGINPAGE.required' | translate
					}}</mat-error>
					<mat-error *ngIf="loginForm.controls.email.errors?.email">{{ 'LOGnlINPAGE.noemail' | translate }}</mat-error>
				</mat-form-field>
			</div>
			<div style="display: flex; justify-content: space-between">
				<button mat-raised-button class="btn" type="submit" color="primary" [disabled]="!loginForm.valid">
					{{ 'LOGINPAGE.login' | translate }}
				</button>
				<mat-chip-listbox #chipList>
					<mat-chip-option [selected]="langSelected === 'nl'" (click)="updateLang('nl')">nl</mat-chip-option>
					<mat-chip-option [selected]="langSelected === 'fr'" (click)="updateLang('fr')">fr</mat-chip-option>
					<mat-chip-option [selected]="langSelected === 'en'" (click)="updateLang('en')">en</mat-chip-option>
					<mat-chip-option [selected]="langSelected === 'de'" (click)="updateLang('de')">de</mat-chip-option>
				</mat-chip-listbox>
			</div>
		</form>
	</mat-card-content>
</mat-card>
