import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UrlService } from '../../_services/url.service';
import { MyflightFlights } from 'src/gen-types';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements OnInit, AfterViewInit {
	myflight: any = null;
	booking: any = null;
	tenantInfo: any = null;
	operatorName: string = '';
	flights: any = null;
	error: boolean = false;
	isCanceled: boolean = false;
	isComplete: boolean = false;
	flightPickerDisabled: boolean = false;
	isMobile: boolean = false;

	constructor(
		private urlService: UrlService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private dateAdapter: DateAdapter<Date>,
		breakpointObserver: BreakpointObserver
	) {
		// Observe screenSize and let other components know through the responsive service
		breakpointObserver.observe([Breakpoints.Small]).subscribe((result) => {
			this.isMobile = result.matches;
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			const lang = params['lang'];
			// Now you can use the lang variable
			this.updateLang(lang);
		});

		this.urlService.myflight.subscribe(
			(myflight) => {
				this.error = false;
				if (myflight === 'error') {
					console.log('Not possible to fetch the booking');
					this.error = true;
					return;
				}
				this.myflight = myflight;
				if (this.myflight && this.myflight.booking) {
					this.booking = this.myflight.booking;
					this.isCanceled = this.booking.status === 'CANC';
					// this.isComplete = this.booking.status === 'COMP';
					// } else {
					// 	console.log('No data or booking data found');
					// 	this.error = true;
				}
				if (this.myflight && this.myflight.tenantSetting) {
					this.tenantInfo = this.myflight.tenantSetting;

					if (this.tenantInfo.operatorSettings.myflight.flights === MyflightFlights.None) {
						this.flightPickerDisabled = true;
					}
				}

				// if (this.myflight && this.myflight.flights) {
				// 	this.flights = this.myflight.flights;
				// }
			},
			(error) => {
				console.log('Not possible to fetch the booking');
				console.log(error);
				this.error = true;
			}
		);
	}

	ngAfterViewInit() {
		// wait 0.5sec, so that the DOM can be updated
		setTimeout(() => {
			this.urlService.getFlights().subscribe();
		}, 500);
	}

	validLocation(location: any) {
		if (!location) return false;
		if (!location.latitude) return false;
		if (!location.longitude) return false;
		return true;
	}

	updateLang(language: any) {
		let langSelected = '';
		let dateLocale = '';

		switch (language) {
			case 'nl': {
				langSelected = 'nl';
				dateLocale = 'nl-BE';
				break;
			}
			case 'fr': {
				langSelected = 'fr';
				dateLocale = 'fr-BE';
				break;
			}
			case 'en': {
				langSelected = 'en';
				dateLocale = 'en-US';
				break;
			}
			case 'de': {
				langSelected = 'de';
				dateLocale = 'de-DE';
				break;
			}
		}
		if (langSelected != '' && dateLocale != '') {
			this.translate.setDefaultLang(langSelected);
			this.translate.use(langSelected);
			this.dateAdapter.setLocale(dateLocale);
			dayjs.locale(langSelected);
		}
	}
}
